import styled from 'styled-components';

export const AboutSection = styled.section`
	p {
		${tw`mt-3 leading-loose`};
	}

	a {
		border-bottom: 3px solid #f8ff00;
		transition: ease all 0.2s;
		&:hover {
			background: #f8ff00;
			color: #004664;
		}
	}
`;

export const SkillsSection = styled.section`
	${tw`py-20`};
	h5 {
		${tw`mt-8 mb-4`};
	}
	.skills {
		${tw`flex flex-wrap`};
	}
	.skill {
		${tw` mr-4 mb-4`};
		&__btn {
			${tw`text-16 sm:text-20 font-sans font-bold px-5 py-2 text-grey-darker bg-red-lightest rounded-full`};
			transition: ease all 0.2s;
		}
		&:hover {
			.skill__btn {
				${tw`bg-red-light text-white`};
			}
		}
	}
	.skills:nth-of-type(2) {
		.skill {
			&__btn {
				${tw`bg-orange-lightest`};
			}
			&:hover {
				.skill__btn {
					${tw`bg-orange`};
				}
			}
		}
	}
	.skills:nth-of-type(3) {
		.skill {
			&__btn {
				${tw`bg-yellow-lightest`};
			}
			&:hover {
				.skill__btn {
					${tw`bg-yellow text-grey-darker`};
				}
			}
		}
	}
	.skills:nth-of-type(4) {
		.skill {
			&__btn {
				${tw`bg-green-lightest`};
			}
			&:hover {
				.skill__btn {
					${tw`bg-green`};
				}
			}
		}
	}
	.skills:nth-of-type(5) {
		.skill {
			&__btn {
				${tw`bg-blue-gray-light`};
			}
			&:hover {
				.skill__btn {
					${tw`bg-blue-gray`};
				}
			}
		}
	}
	.skills:nth-of-type(6) {
		.skill {
			&__btn {
				${tw`bg-purple-lightest`};
			}
			&:hover {
				.skill__btn {
					${tw`bg-purple-light`};
				}
			}
		}
	}
`;
