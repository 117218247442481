import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Template from '../../layouts/Template';
import { AboutSection } from '../about/_Styles';

const Coffee = ({ data }) => (
	<Template title={`Coffee Recipe | ${data.site.siteMetadata.title}`} desc="My secret coffee page">
		<AboutSection>
			<div className="wrapper">
				<h1>Coffee</h1>
				<p>I love coffee.</p>
				<p>Fun fact: I own around 10 different types of coffee makers.</p>
				<p>
					My current favorite recipe is 20 g of Coffee, 340 g of 200 degree F water, poured through a{' '}
					<a
						href="https://www.amazon.com/Kalita-04021-Stainless-Dripper-Coffee/dp/B004W5L1XY"
						target="_blank"
						rel="noopener noreferrer"
					>
						Kalita Wave Coffee Maker
					</a>
					.
				</p>
				<p>
					Much like this:{' '}
					<a href="https://www.youtube.com/watch?v=fAhWuYA_WyU" target="_blank" rel="noopener noreferrer">
						Watch on YouTube
					</a>
				</p>
			</div>
		</AboutSection>
	</Template>
);
Coffee.propTypes = {
	data: PropTypes.object.isRequired,
};
export default Coffee;

export const pageQuery = graphql`
	query CoffeeQuery {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
